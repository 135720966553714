@import 'mixins';

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

#root {
  white-space: pre-line;
}

:root {
  // Font families
  --golos: 'Golos Text', sans-serif;
  --roboto: 'Roboto', sans-serif;
  --russo-one: 'Russo One', sans-serif;
  --montserrat-bold: 'Montserrat Bold', sans-serif;
  --pf-din-text-cond-pro: 'pf_din_text_cond_pro', sans-serif;

  --max-container-width: 112rem;
  --content-padding: 4rem;
  --primary-color: #f1000d;
  --header-height: 7rem;

  --mobile-container-width: 480px;

  // Content
  --content-padding-horizontal: 4rem;
  --content-padding-vertical: 2rem;

  --page-content-padding: 16px;

  // Round colors
  --black-text: #333333;
  --black-text-alpha-30: rgba(51, 51, 51, 0.3);
  --blue-dark: #0a2677;
  --blue-input: #eaeff4;
  --blue-light: #f5f7f9;
  --dark-green: #6bb33e;
  --olive: #6b8e23;
  --dark-primary: #2f2f30;
  --dark-primary-alpha-80: rgba(47, 47, 48, 0.8);
  --footer: #5a5961;
  --gray-20: #f0f0f2;
  --gray-30: #e1e1e6;
  --gray-50: #a3a3a6;
  --gray-80: #7a7a7d;
  --gray-light: #c6c8cb;
  --mid-violet: #e2ceff;
  --new-black: #2e2935;
  --pink: #d049e1;
  --red-err: #fc5b19;
  --violet: #8733ff;
  --violet-deep: #492977;
  --violet-hover: #5d12c8;
  --white-alpha-30: rgba(255, 255, 255, 0.3);

  // Round gradients
  --gradient: linear-gradient(135deg, #8733ff 0%, #e551f7 100%);
  --new-gradient: linear-gradient(349.37deg, #7528af 4.44%, #992ec5 141.99%);
  --web: linear-gradient(82.56deg, #ff0000 0%, #eb3bfe 99.99%, #ff00d6 100%);

  --box-shadow: 0 10px 18px #0f001e26, 0 0 1px #0f001e33;
  --menu-bar-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.1);

  @include breakpoint(mobile) {
    --content-padding: 2rem;
    --header-height: 4.2rem;

    // Content
    --content-padding-horizontal: 2rem;
    --content-padding-vertical: 1rem;
  }
}

html,
body {
  -ms-overflow-style: scrollbar;
  --odnoklassniki-color: #ff9800;
  --vkontakte-color: #4986cc;
}

html {
  box-sizing: border-box;
  font-size: 10px;
  -webkit-text-size-adjust: 100%;

  @include noHighlight();

  @include breakpoint(desktop) {
    font-size: 9px;
  }

  @include breakpoint(laptop) {
    font-size: 8px;
  }

  @include breakpoint(tablet) {
    font-size: 7px;
  }

  @include breakpoint(small-tablet) {
    font-size: 6px;
  }

  @include breakpoint(mobile) {
    font-size: 10px;
  }
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--black);
  font-family: var(--golos);
}

main {
  display: block;
}

ul,
ol {
  list-style: none;
}

ol {
  counter-reset: ordered-list;
  li {
    counter-increment: ordered-list;
    &::before {
      content: counter(ordered-list) '. ';
    }
  }
}

input,
textarea {
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
  box-shadow: none;
  width: 100%;
  background-color: transparent;
  display: block;
}

input:not([type='checkbox']):not([type='radio']) {
  appearance: none;
  -webkit-appearance: none;
}

textarea {
  resize: none;
  box-shadow: none;
  display: block;
  width: 100%;
  overflow: auto;
  height: 20rem;
  @include customScrollbar();
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  -webkit-appearance: none;
  font: inherit;
  color: inherit;
  outline: none;
  display: inline-block;
  position: relative;
  transition: color 0.2s;
}

a {
  background-color: transparent;
  text-decoration: none;
  transition: color 0.2s;
  &:link,
  &:visited {
    color: inherit;
  }
}

svg {
  flex-shrink: 0;
  transition: fill 0.2s;
}

img {
  border-style: none;
  max-width: 100%;
  display: block;
}

img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

img.lazyload:not([src]) {
  visibility: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: 400;
}

td,
th {
  text-align: left;
  vertical-align: top;
}

::selection {
  background-color: var(--brand-primary-light);
  color: var(--stable-text-default);
}

::-moz-selection {
  background-color: var(--brand-primary-light);
  color: var(--stable-text-default);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
}

.sticky {
  position: fixed;
  top: 0;
  @include mobileContainerWidth();
}

.prevent-scroll {
  overflow: hidden;
  pointer-events: none;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background: var(--gray-50);
  opacity: 1;
}
.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--brand-primary);
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}
